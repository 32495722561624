import React from "react";
import { Container } from "react-bootstrap";
import { Title, SubTitle, Description, MainSection } from "../style";
const Termcondition = ({ title }) => {
    return (
        <MainSection>
            <Container className="w-75">
                <Title>{title}</Title>

                <Description>
                    Below are the Terms and Conditions for use of
                    <a href="https://www.woorg.com/">https://www.woorg.com/</a>.
                    Please read these carefully. If you need to contact us
                    regarding any aspect of the following terms of use of our
                    website, please contact us on the following email address -{" "}
                    <a href="info@woorg.com">info@woorg.com</a>.
                    <br />
                    <br />
                    By accessing the content of www.woorg.com ( hereafter
                    referred to as website ) you agree to the terms and
                    conditions set out herein and also accept our Privacy
                    Policy. If you do not agree to any of the terms and
                    conditions you should not continue to use the Website and
                    leave immediately.
                    <br />
                    <br />
                    You agree that you shall not use the website for any illegal
                    purposes, and that you will respect all applicable laws and
                    regulations.
                    <br />
                    <br />
                    <br />
                    <br />
                    You agree not to use the website in a way that may impair
                    the performance, corrupt or manipulate the content or
                    information available on the website or reduce the overall
                    functionality of the website.
                    <br />
                    <br />
                    You agree not to compromise the security of the website or
                    attempt to gain access to secured areas of the website or
                    attempt to access any sensitive information you may believe
                    exist on the website or server where it is hosted.
                    <br />
                    <br />
                    You agree to be fully responsible for any claim, expense,
                    losses, liability, costs including legal fees incurred by us
                    arising from any infringement of the terms and conditions in
                    this agreement and to which you will have agreed if you
                    continue to use the website.
                    <br />
                    <br />
                    <br />
                    <br />
                    The reproduction, distribution in any method whether online
                    or offline is strictly prohibited. The work on the website
                    and the images, logos, text and other such information is
                    the property of www.woorg.com ( unless otherwise stated ).
                </Description>
                <SubTitle>Disclaimer</SubTitle>
                <Description>
                    Though we strive to be completely accurate in the
                    information that is presented on our site, and attempt to
                    keep it as up to date as possible, in some cases, some of
                    the information you find on the website may be slightly
                    outdated.
                    <br />
                    <br />
                    www.woorg.com reserves the right to make any modifications
                    or corrections to the information you find on the website at
                    any time without notice.
                </Description>
                <SubTitle>Change to the Terms and Conditions of Use</SubTitle>
                <Description>
                    We reserve the right to make changes and to revise the above
                    mentioned Terms and Conditions of use.
                </Description>
            </Container>
        </MainSection>
    );
};

export default Termcondition;

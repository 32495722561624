import React from "react";
import Termcondition from "../components/privacy/term-condition/index";
import PropTypes from "prop-types";
import Layout from "@layout";
import SEO from "@components/seo";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import ProjectInfo from "../components/projectInfo";
const TermconditionPage = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    // const content = normalizedData(data?.page.content || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO
                title="Terms and Conditions at"
                pathname="/termsnconditions"
                description="Woorg : The fastest growing performance marketing and advertising agency with clients around 5 continents in multiple industries. Connect now to boost your business."
            />
            <Termcondition title="Terms and Conditions" />
            <ProjectInfo />
        </Layout>
    );
};
TermconditionPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query TermconditionPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    socials {
                        color
                        link
                        icon
                    }
                    ...Footer
                }
            }
        }
    }
`;
export default TermconditionPage;
